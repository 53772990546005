/**
 * External dependencies.
 */
import React from 'react';

import './style.scss';

/**
 * Component to show loader.
 */
const Loader = () => {
	return (
		<div className="loader">
			<div className="loader-svg"></div>
		</div>
	);
};

export default Loader;
