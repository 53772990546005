/* globals Grnhse */

/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';

/**
 * Internal dependencies.
 */
import Loader from './../loader';
import { isUndefined } from 'lodash';

import './style.scss';

/**
 * SpotIMJobs Component.
 *
 * @return {jsx}
 */
const SpotIMJobs = () => {

	const [loading, setLoading] = useState(true);

	const loadScript = () => {

		const url = new URL(window.location.href);
		const jobId = url.searchParams.get('gh_jid');

		if (null === jobId) {
			setLoading(false);
			return;
		}

		// Load script only once in the entire SPA.
		if (true === window.spotIMJobsScriptAdded) {
			setLoading(false);
			return;
		}

		setLoading(true);

		const script = document.createElement('script');

		script.async = true;
		script.onload = () => {
			setLoading(false);
		};
		script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=openweb';


		document.head.appendChild(script);
		window.spotIMJobsScriptAdded = true;

		if ('undefined' === typeof Grnhse || isUndefined(Grnhse.Iframe)) {
			return;
		}


		Grnhse.Iframe.load(jobId);
	};

	/**
	 * Load Iframe.
	 */
	const loadIframe = () => {
		if ('undefined' === typeof Grnhse || isUndefined(Grnhse.Iframe)) {
			return;
		}

		const url = new URL(window.location.href);
		const jobId = url.searchParams.get('gh_jid');

		Grnhse.Iframe.load(jobId);
	};

	useEffect(() => {
		loadScript();
	}, []); // eslint-disable-line

	useEffect(() => {
		if (!loading) {
			loadIframe();
		}
	}, [loading]); // eslint-disable-line

	return (
		<>
			{loading && <div className="jobs-content"><Loader /></div>}
			<div id="grnhse_app" />
		</>
	);
};

export default SpotIMJobs;
