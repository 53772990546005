/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import JobsList from '../../components/careers/jobs';
import SpotIMJobs from '../../components/spot-im-jobs';


import SEO from "../../components/spot-im-jobs/seo";
import { getOgImage } from "./../../utils/functions";

/**
 * Jobs Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
export const Jobs = (props) => {

	const { pageContext: { page: { title, seo, uri }, jobs = {} } } = props;

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title={title} uri={uri} className="jobs-page">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>

			<SpotIMJobs />
			{jobs && <JobsList
				jobs={jobs}
			/>}
		</Layout>
	)
};

Jobs.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Jobs;
